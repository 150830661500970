import './style.css';

const NavTitle = ({ text }) => {
    return (
        <p className="RNavTitle">
            {text}
        </p>
        // <Button startIcon={icon} variant="contained" id="RNavItem" className={ " "+ (isActive ? "active" : "")} {...props} >
        //     {text}
        // </Button>
    )
}

export default NavTitle;