import { useContext, useEffect, useState } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { AuthContext } from "features/auth/store/auth.provider";
import { useHotkeys } from 'react-hotkeys-hook'

import { Alert, CircularProgress } from '@mui/material';

// import TitleLayout from 'components/TitleLayout';
import { useAuth } from 'features/auth/hooks/useAuth';

import logo from 'asset/logo/outilitri_white.png';

import './style.css';

const init_user = {
  email: '',
  password: '',
  error: ''
}

const Login = () => {

  const auth = useContext<any>(AuthContext);
  const url = useResolvedPath("").pathname;
  const navigate = useNavigate();
  const [userLogin, setUserLogin] = useState(init_user);

  useHotkeys('enter', (event) => submit(event))

  const { login } = useAuth();

  useEffect(() => {
    if(auth.user?._id !== "") {
      // console.log(auth.user);
      navigate(getUrlToConnect(), { replace : true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    if(login.isSuccess) {
      navigate(getUrlToConnect(), { replace : true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login.isSuccess]);

  useEffect(() => {
    // console.log(login);
    if(login.isError) {
      setUserLogin({...userLogin, 'error' : "Le login et/ou le mot de passe sont incorrects. \n" });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login.isError]);

  const handleInputChange = (event) => {
    setUserLogin({ ...userLogin, [event.target.id]: event.target.value });
  }

  const validateForm = (data) => {
    let isValidate = true;
    let error = "";
    if (data.email === "") {
      error = "L'email ne peut pas être vide \n";
      isValidate = false;
    }
    if (data.password === "") {
      error = "Le mot de passe ne peut pas être vide. \n";
      isValidate = false;
    }
    if (!isValidate) setUserLogin({ ...userLogin, 'error': error });

    return isValidate;
  }

  const submit = (event) => {
    event.preventDefault();
    if(validateForm(userLogin)){
      login.mutate(userLogin);
    }
  }

  

  const getUrlToConnect = () => {
    if (url === "/login" || url === "/") {
      return "/dashboard";
    };
    return url;
  }

  return (
    <div className="login-container">
      <div className="login-form-container">
        <img className="login-main-logo" src={logo} alt="Outilitri-logo" title="Logo-Outilitri"/>
        <div className='login-form-content'>
          <div className="login-form-inputs">
            <p className='login-form-title'>Connexion</p>
            <form>
              {/* <label className="login-form-label" htmlFor="email"> Email</label> */}
              <input className="login-form-input" type="text" placeholder='Email'
                        defaultValue={userLogin.email} onChange={handleInputChange} 
                          autoComplete="email" id="email" />
              {/* <label className="login-form-label" htmlFor="password"> Mot de passe</label> */}
              <input className="login-form-input" type="password" placeholder="Mot de passe"
                      defaultValue={userLogin.password} onChange={handleInputChange} 
                        autoComplete="password" id="password" />
              {
                userLogin.error !== "" ?
                  <Alert severity="error" className='login-form-error'>{userLogin.error}</Alert>
                  :
                  ""
              }
            </form>
          </div>
          <div className="login-button-connexion" onClick={submit}>
            {
              login.isPending ?
                <CircularProgress size={20} color="inherit" />
              : ""
            }
            Se connecter
          </div>
          {/* <Alert severity="info" className='OAlert'>
            <AlertTitle className='OAlertTitle'>Besoin d'un compte Outilitri</AlertTitle>
            Pour obtenir un compte sur notre outil de pilotage et de monitoring. Merci d'envoyer un mail à <strong>contact@vrai-studio.com</strong>
          </Alert> */}
        </div>
      </div>
      <div className='login-background-container'></div>
    </div>
  );
}

export default Login;