import { Suspense, useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './style/reset.css';
import './style/animations.css';
import './style/main.css';
import './style/variables.css';


import { AuthContext } from "./features/auth/store/auth.provider";
import { CircularProgress } from '@mui/material';
import routes from 'routes';

import LoginPage from 'features/auth/pages/Login';
import Layout from 'layout/Layout';

const loading = (
  <div className="pt-3 text-center">
    <CircularProgress />
  </div>
)

const App = () => {
  const auth = useContext<any>(AuthContext);
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, idx) => {
          return route.component && (
            <Route key={idx} path={route.path} element={
              <div className="fade">
                { 
                  route.requiredLogin ? 
                    auth.user?._id ? 
                      <Layout>
                        <Suspense fallback={loading}>
                          <route.component />
                        </Suspense>
                      </Layout>
                    :
                      <LoginPage /> 
                  :
                  
                    <Layout>
                      <Suspense fallback={loading}>
                        <route.component />
                      </Suspense>
                    </Layout>
                }
              </div>
            } />
          )
        })}
      </Routes>
    </BrowserRouter>
  );
}
export default App;
