import { Avatar } from "@mui/material";
import ActionsBar from "components/ActionsBar";
import { useState } from "react";
import { stringAvatar } from "utils/AvatarUtil";
import ProfilMenu from "./ProfilMenu";
import { ExpandMore } from "@mui/icons-material";

import "./style.css";

const UserBar = ({ user }) => {
    const [anchorProfil, setAnchorProfil] = useState<HTMLButtonElement | null>(
        null
    );

    const openProfil = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorProfil(event.currentTarget);
    };
    const closeProfil = () => {
        setAnchorProfil(null);
    };

    const open_profil = Boolean(anchorProfil);

    return (
        <ActionsBar
            leftContent={<></>}
            centerContent={<></>}
            rightContent={
                <RightActionUserBard
                    user={user}
                    open_profil={open_profil}
                    openProfil={openProfil}
                    anchorProfil={anchorProfil}
                    closeProfil={closeProfil}
                />
            }
        />
    );
};

const RightActionUserBard = ({
    user,
    open_profil,
    openProfil,
    anchorProfil,
    closeProfil,
}) => {
    return (
        <div className="user-right-container">
            {/* <div className="user-actions-buttons">
                <Badge badgeContent="" variant="dot" color="primary">
                    <Email />
                </Badge>
                <Badge badgeContent="" variant="dot" color="primary">
                    <Notifications />
                </Badge>
            </div> */}
            <ProfilMenu anchorEl={anchorProfil} open={open_profil} 
                            onClose={closeProfil} handleCloseProfil={closeProfil} />
            <span className="user-profil-button" onClick={openProfil}>
                {
                    user.picture ? null :
                        <Avatar {...stringAvatar(user.firstname + " " + user.lastname)} />
                }
                <ExpandMore />
            </span>
        </div>
    );
};

export default UserBar;
