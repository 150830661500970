import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { API, actionDeleteAPI, actionGetAPI, actionPostAPI } from "controllers/Api.controller";
    
export const useAuth = () => {
    const queryClient = useQueryClient();

    const auth = useQuery({
        queryKey: ['login'],
        queryFn: () => {
            return new Promise((resolve, reject) => {
                actionGetAPI(API.AUTH, "signin", resolve, reject);
            });
        },
        staleTime: Infinity,
        placeholderData : undefined
    })

    const login = useMutation({
        mutationFn : (login: any) => {
            return new Promise((resolve, reject) => {
                actionPostAPI(API.AUTH, "signin", login, resolve, reject);
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['login'] });
        }
    })

    const logout = useMutation({
        mutationFn : () => {
            return new Promise((resolve, reject) => {
                actionDeleteAPI(API.AUTH, "signin", resolve, reject);
            })
        },
        onSuccess: () => {
            queryClient.removeQueries();
        }
    })

    return {
        auth,
        login,
        logout
    }
}