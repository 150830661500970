// export const url = {
//     site : '',
//     websocket : 'http://192.168.1.203:8090/',
// }

export const url = {
    site : 'https://outilitri-staging.recyclagevr.com',
    websocket : 'https://outilitri-staging.recyclagevr.com',
}

export async function fetcher<JSON = any>(
    input: RequestInfo,
    init?: RequestInit
): Promise<JSON> {
    const res = await fetch(input, init)
    return res.json()
}