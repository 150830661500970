import { useContext, memo, useEffect} from 'react';
import { Link } from 'react-router-dom';
import nav from './config/_nav';
import * as MUIIcon from '@mui/icons-material';
import { Grid } from '@mui/material';
import { AuthContext } from 'features/auth/store/auth.provider';

import { createElement } from 'react';

import NavItem from './NavItem';
import NavLink from './NavLink';
import NavTitle from './NavTitle';

import logo from 'asset/logo/outilitri_white.png';

import './style.css';

const Navbar = ( { open = false, setOpen, isMobile = false } ) => {
    const { user } = useContext<any>(AuthContext);
    const logo_id = open !== null ? open ? "logo-open" : "logo-close" : null;
    // const support_button = open !== null ? open ? "support-button" : "support-button-close" : null;

    const retractNavBar = () => {
        if(isMobile) return;
        let isOpen = !open;
        localStorage.setItem("openNavbar", isOpen.toString());
        setOpen(!open);
    }

    useEffect(() => {
        if(isMobile) return;
        const isOpen = (!localStorage.getItem("openNavbar") || localStorage.getItem("openNavbar") === "true") ? true : false;
        setOpen(isOpen);
        localStorage.setItem("openNavbar", isOpen.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div id={"main-menu"}>
            {
                isMobile ? null : 
                open != null ?
                    open ?
                        <MUIIcon.ArrowBackIos className='retract-icon' onClick={retractNavBar} />
                    :
                        <MUIIcon.ArrowForwardIos className='retract-icon' onClick={retractNavBar} />
                : null
            }
            <Link to={"/"}>
                <img className="main-logo" id={logo_id} src={logo} alt="Outilitri-logo" title="Logo-Outilitri"/>
            </Link>
            <Grid container spacing={2}>
                <Menu nav={nav} components={{ NavItem, NavTitle, NavLink }} user={user}/>
            </Grid>
            {
                <div className="version-site-web-container">
                    v 2.6.2
                </div>
            }
        </div>
    );
}

const Menu = ({ nav, components, user }) => {
    const createTabComponents = () => {
        var tabComponents = []
        for(var i = 0; i < nav.length; i++){
            if(typeof components[nav[i]._tag] != "undefined"){
                var ComponentTemp = null;
                ComponentTemp = components[nav[i]._tag];
                if(typeof nav[i].icon == "string"){
                    nav[i].icon = MUIIcon[nav[i].icon.replace('Icon', '')];
                    nav[i].icon = createElement(nav[i].icon);
                }
                if(typeof nav[i].condition_appear !== "function") {
                    delete nav[i].condition_appear;
                    tabComponents.push(<ComponentTemp key={i} {...nav[i]}/>);
                } else {
                    if(nav[i].condition_appear(user)) {
                        delete nav[i].condition_appear;
                        tabComponents.push(<ComponentTemp key={i} {...nav[i]}/>);
                    }
                }
            }
        }
        return (tabComponents);
    }
    return (
        <>
            {createTabComponents()}
        </>
    )
}

export default memo(Navbar);


