import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';

import './style.css';

const NavLink = ({ icon, text, link, ...props }) => {
    return (
        <Link to={link} {...props}>
            <Grid item xs={12} id="RNavLink" >
                <i>{icon}</i>
                <p>{text}</p>
            </Grid>
        </Link>
    )
}

export default NavLink;