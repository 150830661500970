import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import InterfaceProvider from './features/misc/store/interface.provider';
import AuthProvider from 'features/auth/store/auth.provider';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <InterfaceProvider>
        <AuthProvider>
          <App />
          <ReactQueryDevtools initialIsOpen />
        </AuthProvider>
      </InterfaceProvider>
    </QueryClientProvider>
  </React.StrictMode>
);