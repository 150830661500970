import { useLocation, Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';

import './style.css';

const NavItem = ({ icon, text, link, ...props}) => {
    const location = useLocation();
    return (
        <Link className="RNavItemLink" to={link} {...props}>
            <Tooltip title={text} placement="right" arrow>
                <div id="RNavItem" className={(location.pathname.includes(link) ? "active" : "")} >
                    <i>{icon}</i>
                    <p>{text}</p>
                </div> 
            </Tooltip>
        </Link>
    )
}

export default NavItem;