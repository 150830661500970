import React from 'react';

// user_module
const Home = React.lazy(() => import('features/home/pages/Home'));
const Garbage = React.lazy(() => import('features/garbage/pages/Garbage'));
const Hardwares = React.lazy(() => import('features/hardware/pages/Hardwares'));
const Consignes = React.lazy(() => import('features/consigne/pages/Consignes'));
const Consigne = React.lazy(() => import('features/consigne/pages/Consigne'));
const Ressources = React.lazy(() => import('features/misc/pages/Ressources'));
const Statistics = React.lazy(() => import('features/game/pages/Statistics'));
const Wastes = React.lazy(() => import('features/waste/pages/Wastes'));

// global_module
const Login = React.lazy(() => import('features/auth/pages/Login'));
const Logout = React.lazy(() => import('features/auth/pages/Logout'));
const Page404 = React.lazy(() => import('features/misc/pages/Page404'));

// admin_module
const Enterprise = React.lazy(() => import('features/enterprise/pages/Enterprise'));
const Enterprises = React.lazy(() => import('features/enterprise/pages/Enterprises'));
const Users = React.lazy(() => import('features/user/pages/Users'));
const Parameters = React.lazy(() => import('features/misc/pages/Parameters'));


const routes = [
// #########################
//           VISITOR
// #########################
  { path: '/', name: 'Accueil', component: Login, requiredLogin : false },
  { path: '/login', name: 'Connexion', component: Login, requiredLogin : false },
  { path: '/logout', name: 'Déconnexion', component: Logout, requiredLogin : true },
  
  // #########################
  //           INTERNE
  // #########################
  { path: '/dashboard', name: 'Dashboard', component: Home, requiredLogin : true },

  // #########################
  //           USER
  // #########################
  { path: '/statistics', name: 'Statistiques', component: Statistics, exact: true, requiredLogin : true },
  { path: '/garbages', name: 'Garbages', component: Garbage, exact : true, requiredLogin : true },
  { path: '/wastes', name: 'Wastes', component: Wastes, exact: true, requiredLogin : true },
  { path: '/consignes', name: 'Consignes', component: Consignes, exact : true, requiredLogin : true },
  { path: '/consignes/:id', name: 'Consigne', component: Consigne, exact : false, requiredLogin : true },
  { path: '/equipments', name: 'Materiels', component: Hardwares, exact: true, requiredLogin : true },
  { path: '/ressources', name: 'Ressources', component: Ressources, exact : true, requiredLogin : true },

  // { path: '/pilotages', name: 'Pilotage', component: PilotagesPage, exact: true, requiredLogin : true },
  // { path: '/pilotage/:id', component: PilotagePage, exact: true, requiredLogin : true },
  
  // #########################
  //           ADMIN
  // #########################

  { path: '/parameters/:tab', name: 'Utilisateurs', component: Parameters, exact : true, requiredLogin : true },
  { path: '/parameters', name: 'Utilisateurs', component: Parameters, exact : true, requiredLogin : true },
  { path: '/users', name: 'Utilisateurs', component: Users, exact : true, requiredLogin : true },
  { path: '/enterprises', name: 'Enterprises', component: Enterprises, exact: true, requiredLogin : true },
  { path: '/enterprises/:id', name: 'Enterprises', component: Enterprise, exact: true, requiredLogin : true },

  { path: '/*', name: 'Erreur', component: Page404, requiredLogin : false },
];

export default routes;
