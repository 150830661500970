
enum ROLES {
    ADMIN = "admin",
    USER = "user"
}
const havePermission = (user, permissions : ROLES[]) => {
    return permissions.includes(user.roles.name);
}

const all_users = [
    ROLES.ADMIN,
    ROLES.USER
]

const nav =  [
    {
        _tag: 'NavItem',
        text: 'Tableau de bord',
        link: '/dashboard',
        icon: 'SupportIcon',
        condition_appear : (user) => {
            return havePermission(user, all_users); 
        }
    },
    // {
    //     _tag: 'NavItem',
    //     text: 'Pilotage',
    //     link: '/pilotages',
    //     icon: 'BuildIcon',
    //     condition_appear : (user) => { 
    //         return havePermission(user, [ROLES.ADMINISTRATION]); 
    //     }
    // },
    {
        _tag: 'NavItem',
        text: 'Statistiques',
        link: '/statistics',
        icon: 'QueryStatsIcon',
        condition_appear : (user) => { 
            return havePermission(user, all_users); 
        }
    },
    {
        _tag: 'NavItem',
        text: 'Mes poubelles',
        link: '/garbages',
        icon: 'DeleteIcon',
        condition_appear : (user) => { 
            return havePermission(user, all_users); 
        }
    },
    {
        _tag: 'NavItem',
        text: 'Mes déchets',
        link: '/wastes',
        icon: 'CycloneIcon',
        condition_appear : (user) => { 
            return havePermission(user, [ROLES.ADMIN]); 
        }
    },
    {
        _tag: 'NavItem',
        text: 'Mes consignes',
        link: '/consignes',
        icon: 'CycloneIcon',
        condition_appear : (user) => { 
            return havePermission(user, all_users); 
        }
    },
    {
        _tag: 'NavItem',
        text: 'Mes appareils',
        link: '/equipments',
        icon: 'DevicesOtherIcon',
        condition_appear : (user) => { 
            return havePermission(user, all_users); 
        }
    },
    {
        _tag: 'NavItem',
        text: 'Mes entreprises',
        link: '/enterprises',
        icon: 'ApartmentIcon',
        condition_appear : (user) => { 
            return havePermission(user, [ROLES.ADMIN]); 
        }
    },
    {
        _tag: 'NavItem',
        text: 'Ressources',
        link: '/ressources',
        icon: 'ArticleIcon',
        condition_appear : (user) => { 
            return havePermission(user, all_users); 
        }
    },
    {
        _tag: 'NavItem',
        text: 'Configuration',
        link: '/parameters',
        icon: 'BuildIcon',
        condition_appear : (user) => { 
            return havePermission(user, [ROLES.ADMIN]); 
        }
    }
]

export default nav;